import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
import Url from 'url-parse';

export class Anchor {
  constructor() {
    window.addEventListener('DOMContentLoaded', this.initAnchor.bind(this));
  }

  initAnchor() {
    const triggers = document.querySelectorAll('a[href^="#"]');
    const header = document.querySelector('header.header');
    const blogHeader = document.querySelector('.blog-header');
    if (!triggers) return;

    triggers.forEach((ele, i) => {
      const url = new Url(ele.href);
      ele.addEventListener('click', function(e) {
        e.preventDefault();
        const target = document.getElementById(url.hash.replace('#', ''));
        if (target) {
          const offset = blogHeader ? blogHeader.clientHeight : header.clientHeight;
          gsap.to(window, { duration:1, ease:'power3.inout', scrollTo:{ y:target, offsetY:offset } });
        }
      });
    });
  }
}
