import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export class Structure {

  constructor() {
    this.lastScrollY = 0;
    window.addEventListener('DOMContentLoaded', this.setupHeader.bind(this));
    window.addEventListener('DOMContentLoaded', this.setupBlogHeader.bind(this));
  }

  setupHeader() {
    const body = document.body;
    const header = document.querySelector('.header');
    const drawer = document.querySelector('.drawer');
    const handle = document.querySelector('.header__nav-handle__button');
    const navHeader = document.querySelectorAll('.drawer__nav__block__header');

    ScrollTrigger.create({
      trigger: document.body,
      start: 'top top-=1',
      endTrigger: document.body,
      end: 'bottom top',
      onEnter: () => {
        header.classList.add('is-scrolled');
      },
      onLeaveBack: () => {
        header.classList.remove('is-scrolled');
      },
    });

    handle.addEventListener('click', (e) => {
      e.preventDefault();
      if (!drawer.classList.contains('is-open')) {
        drawer.classList.add('is-open');
        this.lastScrollY = window.scrollY;
        body.style.overflow = 'hidden';
      } else {
        drawer.classList.remove('is-open');
        window.scrollTo(0, this.lastScrollY);
        body.style.overflow = null;
      }
    });

    navHeader.forEach(elm => {
      const content = elm.nextElementSibling;
      const handle = elm.querySelector('button');
      if (content) {
        handle.addEventListener('click', (e) => {
          e.preventDefault();
          if (elm.classList.contains('is-open')) {
            elm.classList.remove('is-open');
            gsap.to(content, { height:0, duration:0.4, ease:'power2.out', clearProps:true });
          } else {
            elm.classList.add('is-open');
            gsap.to(content, { height:'auto', duration:0.4, ease:'power2.out' });
          }
        });
      }
    });
  }

  setupBlogHeader() {
    const header = document.querySelector('.blog-header');
    if (!header) return;

    // Header scroll action
    ScrollTrigger.create({
      trigger: header,
      start: 'top top',
      endTrigger: document.body,
      end: 'bottom top',
      onEnter: () => {
        header.classList.add('is-scrolled');
      },
      onLeaveBack: () => {
        header.classList.remove('is-scrolled');
      },
    });
  }

}
