window.__app = {};

__app.isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
}

if (__app.isMobile()) {
  document.documentElement.classList.add('mobile');
} else {
  document.documentElement.classList.add('no-mobile');
}
