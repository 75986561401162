import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './include/_modernizr-custom';
import './include/_Utils';

import { Structure } from './include/_Structure';
new Structure();

import { Modal } from './include/_Modal';
new Modal();

import { Anchor } from './include/_Anchor';
new Anchor();

import { Home } from './include/_Home';
new Home();
