export class Modal {
  constructor() {
    this.currentModal = null;
    this.lastScrollY = 0;
    window.addEventListener('DOMContentLoaded', this.initModal.bind(this));
  }

  initModal() {
    const triggers = document.querySelectorAll('[data-modal]');
    const modals = document.querySelectorAll('.block-modal');
    if (!triggers || !modals) return;

    triggers.forEach((ele, i) => {
      const target = document.getElementById(ele.dataset.modal);
      if (target) {
        ele.addEventListener('click', (e) => {
          e.preventDefault();
          this.open(target);
        });
      }
    });

    modals.forEach((ele, i) => {
      const close = ele.querySelector('.block-modal__close');
      close.addEventListener('click', this.close.bind(this));
    });
  }

  open(target) {
    this.currentModal = target;
    target.classList.add('is-open');
    this.lastScrollY = window.scrollY;
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.currentModal.classList.remove('is-open');
    window.scrollTo(0, this.lastScrollY);
    document.body.style.overflow = null;
  }
}
