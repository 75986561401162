import { Swiper, Autoplay, Navigation, Pagination } from 'swiper';
Swiper.use([ Autoplay, Navigation, Pagination ]);

import 'rfdc';

export class Home {
  constructor() {
    if (!document.body.classList.contains('home')) {
      return;
    }

    this.lastWidth = window.innerWidth;

    this.initService();
    this.initAbout();
    this.initMember();
  }

  initService() {
    const slider = document.querySelector('.block-home_service__slider');
    if (!slider) {
      return;
    }

    const options = this.getSliderDefaults('slider');
    options.wrapperClass = 'block-home_service__slider__wrapper';
    options.slideClass = 'block-home_service__slider__item';
    options.navigation.nextEl = '.block-home_service__slider__nav__next';
    options.navigation.prevEl = '.block-home_service__slider__nav__prev';
    options.slidesPerView = 1;
    new Swiper(slider, options);
  }

  initAbout() {
    const slider = document.querySelector('.block-home_about__slider');
    if (!slider) {
      return;
    }

    const container = slider.querySelector('.block-home_about__slider__container');
    const options = this.getSliderDefaults('slider');
    options.wrapperClass = 'block-home_about__slider__wrapper';
    options.slideClass = 'block-home_about__slider__item';
    options.navigation.nextEl = '.block-home_about__slider__nav__next';
    options.navigation.prevEl = '.block-home_about__slider__nav__prev';
    options.slidesPerView = 1;
    let swiper = null;

    const initSwiper = () => {
      if (window.innerWidth > 880) {
        if(!swiper) {
          swiper = new Swiper(container, options);
        }
      } else {
        if(swiper) {
          swiper.destroy(true, true);
          swiper = null;
        }
      }
    }
    initSwiper();

    window.addEventListener('resize', () => {
      if (this.lastWidth === window.innerWidth) {
        return;
      }
      this.lastWidth = window.innerWidth;
      initSwiper();
    });
  }

  initMember() {
    const slider = document.querySelector('.block-home_member__slider');
    if (!slider) {
      return;
    }

    // const container = slider.querySelector('.block-home_member__slider__container')
    const options = this.getSliderDefaults('slider');
    options.wrapperClass = 'block-home_member__slider__wrapper';
    options.slideClass = 'block-home_member__slider__item';
    options.navigation.nextEl = '.block-home_member__slider__nav__next';
    options.navigation.prevEl = '.block-home_member__slider__nav__prev';
    new Swiper(slider, options);
  }

  getSliderDefaults(ns) {
    return {
      containerModifierClass: ns + '-container--',
      noSwipingClass: ns + '--no-swiping',
      slideActiveClass: ns + '__item--active',
      slideBlankClass: ns + '__item--invisible-blank',
      slideDuplicateActiveClass: ns + '__item--duplicate-active',
      slideDuplicateClass: ns + '__item--duplicate',
      slideDuplicateNextClass: ns + '__item--duplicate-next',
      slideDuplicatePrevClass: ns + '__item--duplicate-prev',
      slideNextClass: ns + '__item--next',
      slidePrevClass: ns + '__item--prev',
      slideVisibleClass: ns + '__item--visible',

      centeredSlides: true,
      slidesPerView: 'auto',
      loop: true,
      loopAdditionalSlides: 3,
      speed: 500,
      threshold: 5,

      navigation: {
        disabledClass: ns + '-button--disabled',
        hiddenClass: ns + '-button--hidden',
        lockClass: ns + '-button--lock',
      },
    };
  }
}
